



















import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'

const hasProp = (instance, prop) => {
  const $options = instance.$options || {}
  const propsData = $options.propsData || {}
  return prop in propsData
}

@Component
export default class CountTextarea extends Vue {
  @Prop({ default: 0 }) public readonly limit!: number
  @Prop() public readonly value!: any
  @Prop() public readonly customStyle!: object
  @Prop({ default: 3 }) public readonly minRows!: number
  @Prop({ default: 5 }) public readonly maxRows!: number

  public content = this.value ? this.value.content : ''
  public wordCount = 0

  public get wordLimit(): number {
    return this.limit || 0
  }

  public get textareaSize(): object {
    return {
      minRows: this.minRows,
      maxRows: this.maxRows,
    }
  }

  @Watch('value', { immediate: true })
  public onValueChange(val = { content: '' }): void {
    this.content = val.content
    this.countContent(val.content)
  }

  public countContent(value): void {
    const content = value || ''
    const length = content.length
    this.wordCount = Math.ceil(length)
  }

  public changeContent(e): void {
    console.log(111)

    const content = e.target.value
    // const { length, text } = this.getContentLength(content)
    if (!hasProp(this, 'value')) {
      this.content = content
    }
    const length = content.length
    this.wordCount = length
    this.triggerChange(content)
  }

  public getContentLength(content: string): { length: number; text: string } {
    let length = 0
    let text = ''
    for (let i = 0; i < content.length; i++) {
      const a = content.charAt(i)
      const tempLength = length
      if (a.match(/[^\x00-\xff]/gi) !== null) {
        length += 1
      } else {
        length += 0.5
      }
      if (length > 2000) {
        text = content.slice(0, i)
        length = tempLength
        break
      } else {
        text = content
      }
    }
    return { length, text }
  }

  @Emit('change')
  public triggerChange(changedValue) {
    return { content: changedValue }
  }
}
