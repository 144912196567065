











































































































import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import { AttendanceController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import { calcBg, calcSymbol } from '@/utils/utils'
import { statusList } from '@/constant/constant'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class StudentDetail extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly studentId!: number
  @Prop() private readonly studentName!: string

  private date = moment().startOf('month')
  private data: any = {}
  private loading: boolean = false
  private periods: Array<any> = []
  private calcBg = calcBg
  private calcSymbol = calcSymbol

  private get statusList(): any {
    return this.$store.state.attendanceStatusList.length
      ? this.$store.state.attendanceStatusList
      : statusList
  }

  private moment = moment

  private get rows(): any {
    const preWeekDay = this.date.isoWeekday()
    let startDate = moment(this.date).subtract(preWeekDay, 'day')
    // let tmpData = {}
    // this.data.forEach(item => {
    //   this.$set(tmpData, moment(item.date).date(), item)
    // })
    const rows = Array.from({ length: 6 }).map((row, ridx) => {
      return Array.from({ length: 7 }).map((item, idx) => {
        const day = moment(startDate.add(1, 'day'))
        let status = 'noRecords'
        let comment = ''
        let leaveInfo = []
        let editable = true
        if (day.isSame(this.date, 'month')) {
          let data = (this.data || {})[day.date()] || {}
          status = data.status || 'noRecords'
          comment = data.comment
          editable = data.editable
          leaveInfo = data.leaveInfo
        }
        return {
          day: day.date(),
          time: day,
          timeString: day.format('YYYY-MM-DD'),
          type: day.isSame(this.date, 'month')
            ? 'cur'
            : day.isBefore(this.date, 'month')
            ? 'pre'
            : 'next',
          status,
          editable,
          comment,
          leaveInfo,
        }
      })
    })
    return rows
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  private get lastDisabled(): any {
    return (
      moment(this.date).subtract(1, 'month') <=
      moment(this.currentSchoolYear.startTime).startOf('day')
    )
  }

  private get nextDisabled(): any {
    return moment(this.date).add(1, 'month') >= moment(this.currentSchoolYear.endTime).endOf('day')
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.date = moment().startOf('month')
      this.getAttendance()
    }
  }

  private getAttendance(): void {
    this.loading = true
    this.data = {}
    AttendanceController.getKGStudentAttendance(this.studentId, this.date.valueOf())
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private prevWeek(): void {
    if (this.loading || this.lastDisabled) return
    this.date = moment(this.date).subtract(1, 'month')
    this.getAttendance()
  }

  private nextWeek(): void {
    if (this.loading || this.nextDisabled) return
    this.date = moment(this.date).add(1, 'month')
    this.getAttendance()
  }

  private setAttendance(day, status, comment): void {
    this.$emit('echoModify', true)
    const condition = {
      studentId: this.studentId,
      date: day.time.valueOf(),
      status: status,
      comment: comment || '',
    }
    AttendanceController.setAttendanceKG(condition)
      .then(res => {
        if (!Object.keys(this.data || {}).includes(day.day)) {
          this.$set(this.data, day.day, {
            comment: '',
            date: day.time.valueOf(),
            status: status,
            editable: true,
          })
        } else {
          this.data[day.day].status = status
        }
        this.$message.success(this.$tc('tips.saveSuccess'))
      })
      .catch(err => console.log(err))
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
