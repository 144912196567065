









































































































































































































































































































































































import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import {
  AttendanceController,
  DormitoryAttendanceController,
  SsRoomAttendanceController,
} from '@/services/request.service'
import moment, { Moment } from 'moment'
import { calcBg, calcSymbol } from '@/utils/utils'
import { statusList } from '@/constant/constant'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class StudentDetail extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly studentId!: number
  @Prop() private readonly studentName!: string
  @Prop() private readonly isDay!: boolean

  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private data: Array<any> = []
  private periods: Array<any> = []
  private calcBg = calcBg
  private calcSymbol = calcSymbol

  private get statusList(): any {
    return this.$store.state.attendanceStatusList.length
      ? this.$store.state.attendanceStatusList
      : statusList
  }

  private moment = moment

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.startTime = moment().startOf('isoWeek')
      this.endTime = moment().endOf('isoWeek')
      this.getAttendance()
    }
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  private get lastDisabled(): any {
    return (
      moment(this.endTime).subtract(1, 'week') <=
      moment(this.currentSchoolYear.startTime).startOf('day')
    )
  }

  private get nextDisabled(): any {
    return (
      moment(this.startTime).add(1, 'week') >= moment(this.currentSchoolYear.endTime).endOf('day')
    )
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private getAttendance(): void {
    if (this.isDay) {
      AttendanceController.getEleStudentStatistics(
        this.currentSchoolYear.schoolYearId,
        this.startTime.valueOf(),
        this.endTime.valueOf(),
        this.studentId
      ).then(res => {
        this.data = res.data.map(item => ({ ...item, leaveInfo: item.leaveInfo || [] }))
        this.periods = res.data[0].classPeriods
      })
    } else {
      AttendanceController.getStudentStatistics(
        this.currentSchoolYear.schoolYearId,
        this.studentId,
        this.startTime.valueOf(),
        this.endTime.valueOf()
      )
        .then(res => {
          this.data = res.data.map(item => ({ ...item, leaveInfo: item.leaveInfo || [] }))
          this.periods = res.data[0].classPeriods
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  private prevWeek(): void {
    const startTime = moment(this.startTime).subtract(1, 'week')
    const endTime = moment(this.endTime).subtract(1, 'week')
    if (endTime <= moment(this.currentSchoolYear.startTime).startOf('day')) return
    this.startTime = moment(startTime)
    this.endTime = moment(endTime)
    this.getAttendance()
  }

  private nextWeek(): void {
    const startTime = moment(this.startTime).add(1, 'week')
    const endTime = moment(this.endTime).add(1, 'week')
    if (startTime >= moment(this.currentSchoolYear.endTime).endOf('day')) return
    this.startTime = moment(startTime)
    this.endTime = moment(endTime)
    this.getAttendance()
  }

  private setPeriodAttendance(row, key, col): void {
    const period = row.attendances[col.description]
    const condition = {
      classArrangeId: period.classArrangeId,
      studentId: this.studentId,
      status: key,
    }
    this.$emit('echoModify', true)
    AttendanceController.updateClassAttendance(condition)
      .then(res => {
        this.$set(row.attendances, col.description, {
          ...period,
          status: key,
          comment: row.attendances[col.description]?.comment || '',
        })
        this.$message.success(this.$tc('tips.saveSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => this.getAttendance())
  }

  private setAttendance(row, key, type): void {
    this.$emit('echoModify', true)
    if (type === 'day') {
      const condition = {
        studentId: this.studentId,
        date: row.date,
        status: key,
        comment: row.comment || '',
      }
      AttendanceController.setAttendanceKG(condition)
        .then(res => {
          this.$set(row, 'status', key)
          this.$message.success(this.$tc('tips.saveSuccess'))
        })
        .catch(err => console.log(err))
        .finally(() => this.getAttendance())
    } else {
      const condition = {
        date: row.date,
        type,
        studentId: this.studentId,
        status: key,
      }
      AttendanceController.updateDailyAttendance(condition)
        .then(res => {
          this.$set(row, type, key)
          this.$message.success(this.$tc('tips.saveSuccess'))
        })
        .catch(err => console.log(err))
        .finally(() => this.getAttendance())
    }
  }

  // private checkIconVis(item): any{
  //   return !!item?.courseName || !!item?.classRoom || !!item?.comment
  // }
  private updateDormitoryAtten(row, key, type) {
    const condition = { date: row.date, type, studentId: this.studentId, status: key }
    DormitoryAttendanceController.update(condition).then(res => {
      this.$emit('echoModify', true)
      row.dormitoryAttendances[type].status = key
      this.$message.success(this.$tc('tips.saveSuccess'))
    })
  }

  private updateStudyRoomAtten(row, key, type) {
    const condition = { date: row.date, type, studentId: this.studentId, status: key }
    SsRoomAttendanceController.update(condition).then(res => {
      this.$emit('echoModify', true)
      row.studyRoomAttendances[type].status = key
      this.$message.success(this.$tc('tips.saveSuccess'))
    })
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
