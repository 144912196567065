
























import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import {
  AttendanceController,
  DormitoryAttendanceController,
  SsRoomAttendanceController,
} from '@/services/request.service'
import CountTextarea from '@/components/CountTextarea.vue'
import moment from 'moment'
const operations = {
  class: function(request) {
    return AttendanceController.setAttendanceKG(request)
  },
  course: function(request) {
    return AttendanceController.updateClassAttendance(request)
  },
  daily: function(request) {
    return AttendanceController.updateDailyAttendance(request)
  },
  dormitory: function(request) {
    return DormitoryAttendanceController.update(request)
  },
  studyRoom: function(request) {
    return SsRoomAttendanceController.update(request)
  },
}
@Component({
  components: {
    CountTextarea,
  },
})
export default class AddRemark extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly record!: any
  @Prop() private readonly date!: any
  @Prop() private readonly addType!: string

  private loading = false

  private content = ''

  @Watch('visible', { immediate: true })
  private onVisibleChange(newVal) {
    if (newVal) {
      this.content =
        (this.addType === 'course' ||
        this.addType === 'dormitory' ||
        this.addType === 'studyRoom' ||
        this.addType === 'class'
          ? this.record.comment
          : this.record[`comment${this.record.amOrPm.toUpperCase()}`]) || ''
    }
  }

  private changeContent({ content }) {
    this.content = content
  }

  @Emit('change')
  private closeModal(): boolean {
    this.content = ''
    return false
  }

  @Emit('modify')
  private modify(): boolean {
    return true
  }

  public getContentLength(content: string): { length: number; text: string } {
    let length = 0
    let text = ''
    for (let i = 0; i < content.length; i++) {
      const a = content.charAt(i)
      const tempLength = length
      if (a.match(/[^\x00-\xff]/gi) !== null) {
        length += 1
      } else {
        length += 0.5
      }
      if (length > 2000) {
        text = content.slice(0, i)
        length = tempLength
        break
      } else {
        text = content
      }
    }
    return { length, text }
  }

  private confirm(): void {
    this.loading = true
    if (this.content.length <= 500) {
      const request =
        this.addType === 'course'
          ? {
              studentId: this.record.studentId,
              classArrangeId: this.record.classArrangeId,
              comment: this.content,
            }
          : this.addType === 'class'
          ? {
              date: moment(this.date).valueOf(),
              studentId: this.record.studentId,
              comment: this.content,
              status: this.record.status,
            }
          : {
              date: moment(this.date).valueOf(),
              type: this.record.amOrPm,
              studentId: this.record.studentId,
              comment: this.content,
            }
      operations[this.addType](request)
        .then(res => {
          this.$message.success(this.$tc('common.submitSuccess'))
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
          this.closeModal()
          this.modify()
        })
    } else {
      this.$message.error(this.$t('tips.textLimit', { num: 500 }) as string)
      this.loading = false
    }
  }
}
