var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"kindergarten-detail-modal",attrs:{"visible":_vm.visible,"title":_vm.$t('attendance.detailTitle', { name: _vm.studentName }),"width":1000,"maskClosable":false,"centered":"","destroyOnClose":"","footer":false},on:{"cancel":_vm.closeModal}},[_c('div',{staticClass:"student-detail"},[_c('div',{staticClass:"detail-time"},[_c('a-button',{staticClass:"arrow-btn",style:({ color: _vm.lastDisabled ? '#e8e8e8' : '#000' }),attrs:{"type":"link","icon":"left"},on:{"click":_vm.prevWeek}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$d(_vm.date, 'month')))]),_c('a-button',{staticClass:"arrow-btn",style:({ color: _vm.nextDisabled ? '#e8e8e8' : '#000' }),attrs:{"type":"link","icon":"right"},on:{"click":_vm.nextWeek}})],1),_c('table',{staticClass:"attendance-table",attrs:{"width":"100%"}},[_c('thead',[_c('tr',_vm._l((7),function(weekday){return _c('th',{key:weekday,attrs:{"title":_vm.$t(("weekday." + weekday))}},[_c('span',[_vm._v(_vm._s(_vm.$t(("weekday." + weekday))))])])}),0)]),_c('tbody',_vm._l((_vm.rows),function(row,index){return _c('tr',{key:index},_vm._l((row),function(day){return _c('a-dropdown',{key:day.timeString,attrs:{"getPopupContainer":function () { return _vm.$document.body; },"disabled":!day.editable || day.type !== 'cur' || _vm.moment(day.timeString).isAfter(_vm.moment()),"trigger":['click']}},[_c('td',{style:({
                backgroundColor: _vm.calcBg(day.status),
                color:
                  day.type === 'cur'
                    ? !['noRecords', 'weekendHoliday'].includes(day.status)
                      ? '#fff'
                      : 'rgba(0, 0, 0, 0.45)'
                    : '#e8e8e8',
              })},[_c('div',{staticStyle:{"min-height":"21px"}},[_vm._v(_vm._s(_vm.calcSymbol(day.status)))]),_c('a-tooltip',{attrs:{"title":day.comment || '',"getPopupContainer":function () { return _vm.$document.body; }}},[(day.comment)?_c('a-icon',{staticClass:"remark",attrs:{"theme":"filled","type":"message"}}):_vm._e()],1),(day.leaveInfo && day.leaveInfo.length)?_c('a-tooltip',{attrs:{"overlayStyle":{ maxWidth: '320px' }},scopedSlots:_vm._u([(day.leaveInfo && day.leaveInfo.length)?{key:"title",fn:function(){return [_vm._l((day.leaveInfo),function(item){return [_c('div',{key:("time-" + (item.startTime))},[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveTime'))+"：")]),_c('span',[_vm._v(_vm._s(_vm._f("doubleMoment")([item.startTime, item.endTime],'YYYY.MM.DD HH:mm')))])]),_c('div',{key:("reason-" + (item.startTime))},[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveReason'))+"：")]),_c('span',[_vm._v(_vm._s(item.reason))])])]})]},proxy:true}:null],null,true)},[_c('svg-icon',{attrs:{"icon":"takeLeave","className":"take-leave-svg"}})],1):_vm._e(),_c('span',{staticClass:"day"},[_vm._v(_vm._s(day.day))])],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":function (ref) {
                            var key = ref.key;

                            return _vm.setAttendance(day, key, day.comment);
}},slot:"overlay"},_vm._l((_vm.statusList),function(item){return _c('a-menu-item',{key:item,style:({
                  backgroundColor: _vm.calcBg(item),
                  color: item !== 'noRecords' ? '#fff' : 'rgba(0, 0, 0, 0.65)',
                })},[_vm._v(_vm._s(_vm.$t('attendance.statusList.' + item)))])}),1)],1)}),1)}),0)]),_c('span',[_vm._v(_vm._s(_vm.$t('attendance.detailTip1')))]),_c('span',[_vm._v(_vm._s(_vm.$t('attendance.detailTip2')))])])])}
var staticRenderFns = []

export { render, staticRenderFns }